<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table class="table-info"
                  border
                  :data="list"
                  v-loading="loading"
                  style="width: 100%">
            <el-table-column
                prop="activitiesNo"
                label="活动ID"
                width="110">
            </el-table-column>
            <el-table-column
                prop="activitiesName"
                label="活动名称"
                width="110">
            </el-table-column>
            <el-table-column
                label="活动类型"
                min-width="50px">
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.activitiesType | formatDesc(typeItem) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="活动时间"
                min-width="80px">
                <template slot-scope="scope">
                    <div>
                        <p>{{ scope.row.activitiesStartTime | dateCus("yyyy-MM-dd hh:mm:ss") }}</p>
                        <p>{{ scope.row.activitiesEndTime | dateCus("yyyy-MM-dd hh:mm:ss") }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="活动状态"
                min-width="50px">
                <template slot-scope="scope">
                    <p>
                        {{ scope.row.activitiesStatus | formatDesc(statusItem) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="活动渠道"
                min-width="50px">
                <template slot-scope="scope">
                    <p>{{ scope.row.activitiesOnlineChannel | formatDesc(channelItem) }}</p>
                    <!--                    <p v-for="(item, index) in scope.row.activitiesOnlineChannel" :key="item.value">-->
                    <!--                        <span>{{item.label}}</span><span-->
                    <!--                        v-if="scope.row.activitiesOnlineChannel.length - index !== 1">，</span>-->
                    <!--                    </p>-->
                </template>
            </el-table-column>
            <el-table-column
                label="活动范围"
                min-width="50px">
                <template slot-scope="scope">
                    <p v-for="(item, index) in scope.row.activitiesCityDtoList" :key="item.cityId">
                        <span>{{ item.cityName }}</span><span
                        v-if="scope.row.activitiesCityDtoList.length - index !== 1">，</span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="关联优惠券"
                min-width="50px">
                <template slot-scope="scope">
                    <div v-for="item in scope.row.productCouponDtos" :key="item.id">
                        {{ item.couponName }}
                        <p>
                            满减：
                            <span v-if="item.couponDerateType === 0">{{ item.couponPrice }}</span>
                            <span
                                v-if="item.couponDerateType === 1">{{
                                    item.couponRandomMinPrice
                                }} - {{ item.couponRandomMaxPrice }}</span>
                            元{{ item.couponDerateType | formatDesc(derateTypeItem) }}
                        </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="创建和编辑"
                min-width="80px">
                <template slot-scope="scope">
                    <div>
                        <p>
                            {{ scope.row.createUserName + '|' }}
                            {{ scope.row.createTime | dateCus("yyyy-MM-dd hh:mm") }}
                        </p>
                        <p v-if="scope.row.updateUserName && scope.row.createUserName !== scope.row.updateUserName">
                            {{ scope.row.updateUserName + '|' }}
                            {{ scope.row.updateTime | dateCus("yyyy-MM-dd hh:mm") }}
                        </p>
                    </div>
                </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--                label="下单时间"-->
            <!--                min-width="60">-->
            <!--                <template slot-scope="scope">-->
            <!--                    {{scope.row.createTime | dateCus("yyyy-MM-dd hh:mm:ss")}}-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column
                :class-name="('table-info-operation')"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        @click="goActivitiesDetail(scope.row.id, 2)">
                        查看
                    </el-button>
                    <el-button
                        type="text"
                        v-if="scope.row.activitiesStatus === 0"
                        @click="goActivitiesDetail(scope.row.id, 1)">
                        修改
                    </el-button>
                    <!--                    <el-button-->
                    <!--                        type="text"-->
                    <!--                        @click="handleCopy(scope.row.orderId)">-->
                    <!--                        复制-->
                    <!--                    </el-button>-->
                    <el-popover
                        placement="top"
                        width="160"
                        trigger="click"
                        v-if="scope.row.activitiesStatus === 0 || scope.row.activitiesStatus === 1"
                        v-model="scope.row.visible"
                        @show="handleTermination(scope.row.id, scope.$index)">
                        <p>确认终止活动</p>
                        <div style="text-align: right; margin: 0">
                            <el-button
                                type="text"
                                @click="scope.row.visible = false"
                                size="mini">
                                取消
                            </el-button>

                            <el-button
                                type="primary"
                                v-debounce="debounceTermination"
                                size="mini">
                                确定
                            </el-button>
                        </div>
                        <el-button
                            slot="reference"
                            style="color: #ff3b30"
                            type="text"
                            size="mini">
                            终止活动
                        </el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">

import {
    activityChannelItem,
    activityEntranceItem,
    activityStatusItem,
    activityTypeItem,
    couponsDerateTypeItem
} from "../../../../data";
import {offlineActivities} from "@/www/urls/promotionUrls";

export default {
    name: '',
    data() {
        return {
            typeItem: activityTypeItem,
            statusItem: activityStatusItem,
            channelItem: activityChannelItem,
            entranceItem: activityEntranceItem,
            derateTypeItem: couponsDerateTypeItem,
            couponInfo: {}
        }
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {
        // 活动详情页
        goActivitiesDetail(id, type) {
            let path = ''
            switch (type) {
                case 1:
                    path = 'editActivity'
                    break;
                case 2:
                    path = 'lookActivity'
                    break;
            }
            this.$router.push(`/${path}/` + id);

        },
        // 活动终止
        handleTermination(id, index){
            this.couponInfo.id = id
            this.couponInfo.index = index
        },
        async debounceTermination(){
            this.list[this.couponInfo.index].visible = false
            let form = {
                id: this.couponInfo.id
            }
            const res = await offlineActivities(form)
            if(res.success && res.data) {
                this.$message({
                    message: '终止活动成功',
                    type: 'success'
                });
                this.$emit('update:termination')
            } else this.$message.error('终止活动失败');
        },

        // 复制
        // handleCopy() {
        //
        // }

    },
    watch: {},
}
</script>
