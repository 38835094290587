<style lang="scss" scoped>

</style>

<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <search-form class="mb10" @update:form="handleSearch"></search-form>
                <data-table :list="list" @update:termination="handleSearch"></data-table>
            </div>
        </el-main>

        <el-footer class="com-eTrip-footer" height="64px">
            <!--            <div class="eTrip-footer-cont">-->
            <pagination-foot
                ref="paginationFoot"
                :total="pageInfo.total"
                @update:pageInfo="handlePageInfo">
            </pagination-foot>
            <!--            </div>-->
        </el-footer>
    </div>
</template>
<script type="text/ecmascript-6">
import searchForm from './components/form'
import dataTable from './components/table'
import paginationFoot from '@/components/pagination-foot'
import {activitiesList} from '@/www/urls/promotionUrls'
// import {activityChannelItem} from '../../data/index'

export default {
    name: '',
    data() {
        return {
            pageInfo: {},
            list: [],
            form: {}
        }
    },
    components: {
        searchForm,
        dataTable,
        paginationFoot
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
        this.handleSearch()
    },
    methods: {
        handleSearch(form) {
            if(form) this.form = form
            this.$refs.paginationFoot.changeCurrent(1)
        },
        handlePageInfo(pageInfo) {
            this.pageInfo = {
                ...this.pageInfo,
                ...pageInfo,
            }
            this.getList();
        },
        async getList() {
            let {
                    pageSize,
                    currentPage
                } = this.pageInfo,
                form = {
                    pageIndex: currentPage,
                    pageSize,
                    ...this.form
                };
            // let channelList = JSON.parse(JSON.stringify(activityChannelItem))
            let res = await activitiesList(form)
            if (res.success && res.data.data) {
                this.pageInfo.total = res.data.total
                let list = res.data.data
                this.list = list;
            }
        },
    },
    watch: {},
}
</script>
