<style lang="scss" scoped>
.activity-list-form {
    &::v-deep {
        .common-list-search {
            .el-select{
                width: 100%;
            }
            .list-search-before {
                width: calc(100% - 286px);
                .el-form-item.el-form-item--small {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }

            .list-search-after {
                width: 286px;

                .el-button + .el-button {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>



<template>
    <div class="activity-list-form">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item class="el-label_w">
                        <el-radio-group v-model="form.activitiesStatus">
                            <el-radio-button label="">全部状态</el-radio-button>
                            <el-radio-button
                                v-for="item in statusItem"
                                :key="item.value"
                                :label="item.value">
                                {{item.label}}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item class="el-label_w">
                        <el-select v-model="form.activitiesType" placeholder="请选择">
                            <el-option
                                v-for="item in typeItem"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.activitiesNo" placeholder="活动ID"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.activitiesName" placeholder="活动名称"></el-input>
                    </el-form-item>
                    <el-form-item>

                        <el-select
                            v-model="form.cityId"
                            filterable
                            placeholder="优惠券适用城市">
                            <el-option
                                v-for="item in cityList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.shortCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after" class="list-search-after_sec">
                <el-button type="primary" @click="handleAdd">添加活动</el-button>
                <el-button @click="handleReset">重置</el-button>
                <el-button type="primary" @click="handleSearch()">搜索</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch'
import {activityStatusItem, activityTypeItem} from '../../../../data'
import {combinationForm} from "@/common/js/common";
import {getCityData} from "@/urls";

export default {
    name: '',
    data() {
        return {
            statusItem: activityStatusItem,
            typeItem: activityTypeItem,
            form: {
                activitiesStatus: '', // 状态
                // activitiesType: '', // 活动类型
                // activitiesNo: '', // 活动id
                // activitiesName: '', //活动名称
            },
            cityList: [],
        }
    },
    components: {
        comListSearch
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
        this.getCityList()
    },
    methods: {
        // 获取城市
        async getCityList() {
            const res = await getCityData(this, '2');
            if (res) this.cityList = res;
        },
        // 添加活动
        handleAdd(){
            this.$router.push('/addActivity')
        },
        // 重置
        handleReset(){
            this.form = {
                activitiesStatus: '' // 状态
            }
            let form = combinationForm(this.form)
            this.$emit('update:form', form)
        },
        // 搜索
        handleSearch(){

            let {
                    activitiesStatus,
                    activitiesType,
                    activitiesNo,
                    activitiesName,
                    cityId,
                } = this.form,
                form = {
                    activitiesStatus,
                    activitiesType,
                    activitiesNo,
                    activitiesName,
                    cityId,
                };
            form = combinationForm(form)
            this.$emit('update:form', form)
        }
    },
    watch: {},
}
</script>
